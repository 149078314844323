import React from 'react'
import Layout from "../components/layout";
import moment from "moment";
import ShareFbButton from "../components/shareFbButton"
import ShareTwButton from "../components/shareTwButton"
import ShareLkButton from "../components/shareLkButton"
import BannerQuestionDesktop from "../images/cartouche1telOct2022.png"
import UrlService from "../services/global/urlService"
import { ATInternetTagService } from './../services/global/ATInternetService';

class ActualityWhithoutImageTemplate extends React.Component {
  data;
  url;
  keywords;
  canonical;

  async componentDidMount() {
    ATInternetTagService.sendPage({page:this.props.pageContext.actu.title ,page_chapter1:'actualites'})
  }

  render() {

    this.data = this.props.pageContext.actu;
    this.canonical = UrlService.addSlashToEndOfUrl(this.data.canonicalUrl)
    this.url = this.props.location.href
    this.keywords = this.data.keywords != null ? this.data.keywords.split() : [];
    

    return (
      <Layout titleSeo={this.data ? this.data.titleSEO : 'actualite'} 
              keywordsSeo={this.keywords} 
              description={this.data ? this.data.description: ''}
              canonical={this.canonical}>

        <section className="pageHeader actu-header">
          <div className="pageHeaderContainer">
            <nav>
              <ul className="breadcrumb">
                <li className="breadcrumbItem"><a href="/" className="breadcrumbItemLink breadcrumbItemLinkHome"><i className="icon-mgc"></i></a></li>
                <li className="breadcrumbItem"><a href="/actualites/" className="breadcrumbItemLink breadcrumbItemLinkMiddle">Actualités</a></li>
                <li className="breadcrumbItem"><span className="breadcrumbItemLink breadcrumbItemLinkMiddle">{this.data ? this.data.title: ''}</span></li>
              </ul>
            </nav>
            <div className="ciblePubDesktop">
              <img src={BannerQuestionDesktop} alt=""/>
            </div>
          </div>
          <div>
            <h3 className="pageHeaderTitle">Actualités</h3>
          </div>
        </section>
        <div className="container">
          <section className="article">
            <div className="articleHeader">
              <div>
                <h4 className="articleHeaderTitle">{this.data ? this.data.title: ''}</h4>
                <p className="articleHeaderDate">Publié le {this.data ?moment(this.data.formattedPublished).format('DD/MM/YYYY'):''}</p>
              </div>
              <div>
                <ul className="articleHeaderSocial">
                  <li className="articleHeaderSocialItem"><ShareFbButton url={this.url} title={this.data ? this.data.title: ''}></ShareFbButton></li>
                  <li className="articleHeaderSocialItem"><ShareTwButton url={this.url} title={this.data ? this.data.title: ''}></ShareTwButton></li>
                  <li className="articleHeaderSocialItem"><ShareLkButton url={this.url} title={this.data ? this.data.title: ''}></ShareLkButton></li>
                </ul>
              </div>
            </div>
            <div className="articleBody">
              <div  className='articleBodyTable' dangerouslySetInnerHTML={{ __html: this.data ? this.data.content:'' }} />
            </div>
            <div>
              <a href='/actualites/' className="return"><i className="fas fa-chevron-left"></i>Consulter toutes les actualités</a>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
export default ActualityWhithoutImageTemplate
